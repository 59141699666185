<template>
  <div class="mx-1">
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
    </b-row>

    <b-collapse visible id="collapse-1">
      <b-form @submit.prevent="onSearch(filterName)">
        <b-card class="filter">
          <b-row>
            <div class="col-md-3">
              <base-select
                name="status"
                label="Stato"
                v-model="filter.status"
                :options="status"
                :taggable="false"
                :multiple="false"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="type"
                label="Tipo"
                v-model="filter.type"
                :options="types"
                :taggable="false"
                :multiple="false"
              />
            </div>
            <div class="form-group col-md-3 align-self-end">
              <b-button
                type="submit"
                variant="lisaweb"
                size="sm"
                v-b-toggle:collapse-1
                >Cerca</b-button
              >
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset</b-button
              >
            </div>
          </b-row>
        </b-card>
      </b-form>
    </b-collapse>
    <log
      :fields="fields"
      :repository="repository"
      :filterName="filterName"
      :resource="resource"
      :ref="tableRef"
    ></log>
  </div>
</template>

<script>
import LisaComunicaMixin from "@/mixins/LisaComunicaMixin";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import Log from "@/components/tables/LisacomunicaLog.vue";
import BaseSelect from "@/components/form/BaseSelect";
import { mapActions } from "vuex";
import moment from "moment";
moment.locale("it");

export default {
  mixins: [CustomFiltersMixin, LisaComunicaMixin],
  data() {
    return {
      repository: "log",
      resource: "log",
      tableRef: "elogTableRef",
      filterName: "filterLog",
      filter: this.initFilter(),
      status: [
        { value: "FAILED", text: "Fallito" },
        { value: "SENDING", text: "Invio in corso" },
        { value: "OK", text: "Completato" },
        { value: "SCHEDULED", text: "Programmato" },
      ],
      types: [
        { value: "SMS", text: "SMS" },
        { value: "EMAIL", text: "Email" },
        { value: "PDF", text: "Pdf" },
      ],
      fields: [
        {
          key: "actions",
          label: "Azioni",
        },
        {
          key: "procedure_id",
          label: "ID Procedura",
        },
        {
          key: "error_message",
          label: "Messaggio di errore",
        },
        {
          key: "status",
          label: "Stato",
        },
        {
          key: "type",
          label: "Tipo",
          formatter: (value) => this.getType(value),
        },
        {
          key: "timestamp",
          label: "Data e ora",
          formatter: (val) => {
            return moment(val).format("DD/MM/YYYY HH:mm:ss");
          },
        },
      ],
    };
  },
  components: {
    Log,
    BaseSelect,
  },
  methods: {
    initFilter() {
      let init = {
        status: null,
        type: null,
      };
      return init;
    },
    getType(value) {
      return this.types.find((type) => type.value === value).text;
    },
    onAdd() {
      this.$router.push({ name: `logs.create` });
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch();
    },
    onClearFilter(name) {
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
    },
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
  },
};
</script>
