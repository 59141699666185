<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <b-card header="true" header-tag="header" v-if="!isLoading">
        <div
          slot="header"
          class="d-flex justify-content-between align-items-center"
        >
          <p class="mb-0 d-inline-block align-middle">
            <b>Dettaglio Log</b>
          </p>
        </div>
        <div>
          <template v-if="data.logObject">
            <div class="grid-custom-wrapper border-bottom">
              <div class="pb-2">Template ID</div>
              <div class="pb-2">
                {{ data.logObject.template_id }}
              </div>
            </div>
            <div class="grid-custom-wrapper border-bottom">
              <div class="py-2">Destinatario</div>
              <div class="py-2">
                {{ data.logObject.recipient }}
              </div>
            </div>
          </template>
          <div
            v-if="data.resolvedContent.subject"
            class="grid-custom-wrapper border-bottom"
          >
            <div class="py-2">Oggetto</div>
            <div class="py-2">
              {{ data.resolvedContent.subject }}
            </div>
          </div>
          <div v-if="data.resolvedContent.text" class="grid-custom-wrapper">
            <div class="pt-2">Testo</div>
            <div class="pt-2" v-html="data.resolvedContent.text"></div>
          </div>
        </div>
      </b-card>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import LisaComunicaMixin from "@/mixins/LisaComunicaMixin";
import BaseIcon from "@/components/BaseIcon";

export default {
  mixins: [LisaComunicaMixin],
  name: "General",
  props: {
    resourceId: Number,
  },
  components: {
    BaseIcon,
  },
  data() {
    return {
      isLoading: true,
      id: this.resourceId,
      repository: "log",
      data: null,
    };
  },
  beforeMount() {
    this.show()
      .then((response) => {
        this.data = response.data.content;
        this.isLoading = false;
      })
      .catch((error) => {
        let errMsg = this.$getErrorMessage(error);
        this.$showSnackbar({
          preset: "error",
          text: `${errMsg}`,
        });
        this.isLoading = false;
        this.ok = true;
      });
  },
};
</script>

<style scoped lang="scss">
.card {
  border: none;
}
.col-md-4.border-bottom {
  text-transform: uppercase;
  color: rgb(63, 60, 60);
  font-size: bold;
}
:deep(.grid-custom-wrapper) {
  display: grid;
  grid-template-columns: 70px 1fr;
  column-gap: 50px;
  row-gap: 5px;
}
</style>
